import React from "react";
import styled from "styled-components";

import Grid from "@mui/material/Grid"; // Grid version 1
// Components
import FullButton from "../Buttons/FullButton";
import ProjectBox from "../Elements/ProjectBox";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";

// import CheckMark from "../../assets/svg/Checkmark";
import CheckMark from "../../assets/img/checkmark.png";

export default function PricingTable({
  icon,
  duration,
  price,
  price2,
  title,
  text,
  color,
  offers,
  action,
  project,
  to,
}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <div style={{ margin: "10px 30px" }}>
      <TopWrapper className="  lightdarkBg whiteColor" shadowColor={color}>
        <div className="textCenter">
          <p className="font20 lightgreyColor textCenter">{duration}</p>
        </div>
        <div style={{ margin: "5px 0" }}>
          <h4 className="font20  textCenter">
            <span class="strikethrough">
              <a className="font20  textCenter ">฿</a>
              {price}
            </span>
          </h4>
          <h4 className="font60  textCenter redColor">
              <a className="font20  textCenter redColor ">฿</a>
              {price2}
          </h4>
        </div>
          
      </TopWrapper>
      <ButtomWrapper className="darkBg whiteColor">
        <div style={{ margin: "7px 0" }}>
          {offers
            ? offers.map((item, index) => (
                <div
                  className="flexNullCenter semiBold lightgreyColor"
                  style={{ margin: "2px 0" }}
                  key={index}
                >
                  <div
                    style={{
                      position: "relative",
                      top: "-1px",
                      marginRight: "6px",
                    }}
                  >
                    {" "}
                    <div>
                      {" "}
                      <CheckMarkStyle src={CheckMark} />
                    </div>
                  </div>
                  <p className="font17 ">{item.name}</p>
                </div>
              ))
            : null}
        </div>
        <br />
        <ProjectBox img={project} />

      
        <br />
        <div style={{ maxWidth: "150px", margin: "0px auto 30px auto" }}>
          <FullButton
            color={color}
            title="ตัวอย่างงาน"
            action={action}
            to={to}
          />
        </div>
      </ButtomWrapper>
    </div>
  );
}

const TopWrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 10px;
  box-shadow: inset ${(props) => props.shadowColor} 0 10px 10px -10px;

  border-radius: 10px 10px 0 0; /* top left and right corners only */
  box-sizing: border-box;
`;

const ButtomWrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 10px 10px;
  margin-buttom: 30px;
  border-radius: 0 0 10px 10px; /* top left and right corners only */
`;

const CheckMarkStyle = styled.img`
  width: 16px;
  height: 8px;
`;
